import _ from 'lodash'
import { safeObject } from 'sdk/utils/object'
import FactoryModel from 'sdk/models/factoryModel'
import BaseHttpProvider from '../base-http-provider'
import FactoryProvider from '../factoryProvider'

class TelephonistProvider extends BaseHttpProvider {
  host = () => `${FactoryProvider.ConfigProvider.config.api.telephonistURI}`
  defaultApiPrefix = '/api/v1'

  async getConfirmCode(phone) {
    const { data } = await this.execute({
      uri: '/send_code',
      method: 'POST',
      params: safeObject({
        phone: `7${phone}`,
        point_id: FactoryModel.AppModel.pointID || null
      }),
      headers: {
        Authorization: `Bearer ${FactoryModel.AppModel.token}`
      }
    })
    return _.get(data, 'confirm_key', '')
  }

  async confirmCode(code, confirmKey) {
    const { data } = await this.execute({
      uri: '/confirm',
      method: 'POST',
      params: {
        code,
        confirm_key: confirmKey
      },
      headers: {
        Authorization: `Bearer ${FactoryModel.AppModel.token}`
      }
    })
    return {
      isSuccess: _.get(data, 'confirmed', false),
      isForPoslineApi: _.findIndex(_.get(data, 'scopes', []), s => s === 'sdk:telephonist:posline') !== -1
    }
  }

  async sendRequest(lastName, firstName, patronymic, clientComment, confirmKey) {
    await this.execute({
      uri: '/request',
      method: 'POST',
      data: safeObject({
        lastname: lastName,
        firstname: firstName,
        patronymic: patronymic || null,
        confirm_key: confirmKey,
        hire: FactoryModel.AppModel.isHire,
        goods: _.map(FactoryModel.AppModel.goods, good => ({
          name: good.name,
          count: good.count,
          price: good.price,
          category: good.category,
          manufacturer: good.manufacturer
        })),
        order_payload: safeObject({
          location: FactoryProvider.DeviceProvider.location(),
          os: FactoryProvider.DeviceProvider.os(),
          device: FactoryProvider.DeviceProvider.device(),
          browser: FactoryProvider.DeviceProvider.browser(),
          comment: FactoryModel.AppModel?.orderPayload?.comment,
          ym_client_id: FactoryModel.AppModel?.orderPayload?.ym_client_id,
          client_comment: clientComment
        })
      }),
      headers: {
        Authorization: `Bearer ${FactoryModel.AppModel.token}`
      }
    })
  }
}

export { TelephonistProvider }
