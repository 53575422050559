import React, { Fragment } from 'react'
import Modal from 'sdk/ui/Modal'
import Button from 'sdk/ui/Button'
import Block from 'sdk/ui/Block'
import { Paragraph, Text, Title } from 'sdk/ui/Typography'
import Input from 'sdk/ui/Input'
import Icon from 'sdk/ui/Icon'
import { openFileLink } from 'sdk/utils/link'
import sfinanceLogo from 'resources/sdk/logo-s-finance.png'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'
import ConfirmCodeForm from './ConfirmCodeForm'

const Logo = styled.img`
  width: 10rem !important;
  margin-bottom: 4px;
`

const HelpModal = ({ DialogModel, PhoneModel }) => {
  const handleSendConfirmCode = async () => {
    await PhoneModel.sendConfirmCode()
  }

  const handleSend = async () => {
    await DialogModel.send()
  }

  const handleConfirm = async () => {
    await PhoneModel.confirm()
  }

  const handleCloseNotifyModal = () => {
    DialogModel.finish()
  }

  const handleShowDetailsModal = () => {
    DialogModel.showDetails(true)
  }

  const handleCloseDetailsModal = () => {
    DialogModel.showDetails(false)
  }

  return (
    <Fragment>
      {!DialogModel?.isSend &&
      <Modal
        title={(
          <Block verticalPlacement="center" width="100%">
            <Logo src={sfinanceLogo}/>
            <Title
                level={4}
                style={{ marginLeft: '15px', marginTop: '6px' }}
            >
              {DialogModel?.dialog?.title}
            </Title>
          </Block>
        )}
        isOpen={DialogModel?.isOpen}
        onClose={() => DialogModel.open(false)}
        width="500px"
        footer={
          <Block horizontalPlacement={!PhoneModel?.isSendPhoneCode ? 'between' : 'center' } verticalPlacement="center">
            {!PhoneModel?.isSendPhoneCode ?
              <>
                <Button
                  type="primary"
                  onClick={handleSendConfirmCode}
                  isDisable={!PhoneModel?.isValidPhone}
                  isLoading={PhoneModel?.isSendConfirmKey}
                >
                  Продолжить
                </Button>
                <Text
                  type="primary"
                  onClick={handleShowDetailsModal}
                  style={{ borderBottom: '1px dashed',
                    lineHeight: '15px' }}
                >
                  Узнать подробнее
                </Text>
              </> :
              !PhoneModel?.isConfirmCode ?
                <Block horizontalPlacement="left" marginTop={1}>
                  <Block marginRight={1} marginTop={-2}>
                    <Input
                      label="Код из СМС"
                      textAlign="center"
                      value={PhoneModel?.confirmCode}
                      onChange={PhoneModel.setField('confirmCode')}
                      width={100} isRem={false}
                      isDisable={PhoneModel?.isLoadingConfirmCode}
                    />
                  </Block>
                  <Button
                    type="primary"
                    onClick={handleConfirm}
                    isDisable={!PhoneModel?.isValidConfirmProcess}
                    isLoading={PhoneModel?.isLoadingConfirmCode}
                  >
                    {!DialogModel.isFillName ? 'Подтвердить' : 'Отправить заявку'}
                  </Button>
                </Block> :
                <Button
                  type="primary"
                  isDisable={!DialogModel?.isSendRequest}
                  onClick={() => handleSend()}
                  isLoading={DialogModel?.isLoading}
                >
                  Отправить заявку
                </Button>
            }
            </Block>
        }
      >
        <Paragraph level={4} marginBottom={2}>
          {DialogModel?.dialog?.subTitle}
        </Paragraph>
        {DialogModel?.dialog?.subTitle2 &&
          <Paragraph level={4} marginBottom={2}>
            {DialogModel?.dialog?.subTitle2}
          </Paragraph>
        }
        <Block horizontalPlacement="between" marginBottom={1}>
          <Block width="49%">
            <Input
              label="Фамилия"
              value={DialogModel?.lastName}
              onChange={DialogModel?.setField('lastName')}
              isDisable={DialogModel?.isLoading || PhoneModel?.isLoadingConfirmCode}
            />
          </Block>
          <Block width="49%">
            <Input
              label="Имя"
              value={DialogModel?.firstName}
              onChange={DialogModel.setField('firstName')}
              isDisable={DialogModel?.isLoading || PhoneModel?.isLoadingConfirmCode}
            />
          </Block>
        </Block>
        <Input
          label="Отчество"
          value={DialogModel?.patronymic}
          onChange={DialogModel.setField('patronymic')}
          isDisable={DialogModel?.isLoading || PhoneModel?.isLoadingConfirmCode}
          marginBottom={1}
        />
        <Input
          label="Телефон"
          value={PhoneModel?.phone}
          onChange={PhoneModel.changePhone}
          mask="(999) 999-99-99"
          unmask={v => v.replace(/[\s()-]*/gi, '')}
          prefix="+7"
          postfix={PhoneModel?.isConfirmCode ? <Icon icon="check" color="success"/> : null}
          isDisable={DialogModel?.isLoading || PhoneModel?.isLoadingConfirmCode}
          marginBottom={1}
        />
        <Input
          label="Дополнительная информация"
          value={DialogModel?.clientComment}
          onChange={DialogModel.setField('clientComment')}
          isDisable={DialogModel?.isLoading || PhoneModel?.isLoadingConfirmCode}
        />
        {!PhoneModel?.isConfirmCode ?
          <ConfirmCodeForm
            phone={PhoneModel?.phone}
            isSendPhoneCode={PhoneModel?.isSendPhoneCode}
          /> :
          <Block marginBottom={3}>
            <Text>
              Телефонный номер подтвержден.
            </Text>
          </Block>
        }
      </Modal>
      }
      {DialogModel?.isSend &&
      <Modal
        isOpen={DialogModel.isOpen}
        onClose={handleCloseNotifyModal}
      >
        <Block padding={2}>
          {!DialogModel?.isSendToPosline ?
            <Block>
              <Paragraph level={4} marginTop={2} marginBottom={2}>
                <Text type="success" size={1.5}>{DialogModel?.dialog?.successText}</Text>
              </Paragraph>
              <Paragraph level={4} marginTop={2} marginBottom={2}>
                <Text type="success" size={1.5}>{DialogModel?.dialog?.additionalSuccessText}</Text>
              </Paragraph>
              {DialogModel?.closeRedirect &&
              <Block isCenter marginTop={2}>
                <Button type="primary" onClick={() => openFileLink(DialogModel?.closeRedirect, false)}>
                  Закрыть
                </Button>
              </Block>
              }
            </Block> :
            <>
            <Block>
              <Text size={1.5}>
                Перейдите по адресу
                <Text type="primary" onClick={() => openFileLink(DialogModel?.poslineRedirectUrl)}> {DialogModel?.poslineRedirectUrl}</Text>
                <Text> для заполнения заявки на кредит</Text>
              </Text>
            </Block>
            <Block isCenter marginTop={2}>
              <Button type="primary" onClick={() => openFileLink(DialogModel?.poslineRedirectUrl)}>
                Перейти
              </Button>
            </Block>
            </>
          }
        </Block>
      </Modal>
      }
      <Modal
        isOpen={DialogModel?.isShowDetails}
        onClose={handleCloseDetailsModal}
        width="500px"
        footer={
          <Block isCenter>
            <Button
                type="primary"
                onClick={handleCloseDetailsModal}
            >
              Закрыть
            </Button>
          </Block>
        }
      >
        <Paragraph level={4} marginTop={2} marginBottom={2}>
          Заявка на кредит/рассрочку рассматривается сразу несколькими кредитными организациями (Кредит Европа Банк, МТС, Т-Банк, Альфа банк, Совком банк, ОТП, Ренессанс, Быстрые покупки, ЛОКО-банк, МигКредит, Уралсиб). После рассмотрения заявки вы сможете выбрать наиболее подходящее для вас предложение.
        </Paragraph>
        <Paragraph level={4} marginBottom={2}>
          Требования к заемщику и параметры кредита:
          <ul>
            <li>Возраст заемщика: от 18 лет</li>
            <li>Сумма кредита: от 3 000 рублей до 1 000 000 рублей</li>
            <li>Срок кредита: от 3 до 36 месяцев</li>
            <li>Переплата по кредиту/рассрочке: рассчитывается индивидуально</li>
          </ul>
        </Paragraph>
        <Paragraph level={4} marginBottom={2}>
          Узнать более подробную информацию по условиям кредитования, а также получить предварительный расчет по кредиту, вы сможете после отправки заявки. В течение 5 минут с вами свяжется кредитный специалист.
        </Paragraph>
      </Modal>
    </Fragment>
  )
}

export default inject(store => ({
  DialogModel: store?.DialogModel,
  PhoneModel: store?.PhoneModel
}))(observer(HelpModal))
