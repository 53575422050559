import { observable, action } from 'mobx'
import { isSafe } from 'sdk/utils/object'
import _ from 'lodash'
import BaseModel from '../baseModel'

class AppModel extends BaseModel {
  @observable isInit = false
  @observable isRender = false
  @observable token = ''
  @observable pointID = ''
  @observable goods = []
  @observable isHire = false
  @observable orderPayload = {
    location: null,
    comment: null,
    ym_client_id: null
  }

  apply = action(data => {
    if (!this.isInit) this.isInit = true
    if (data?.token) this.token = `${data?.token}`.trim()
    if (data?.point_id) this.pointID = data?.point_id
    if (data?.order_payload && !_.isEmpty(data?.order_payload)) {
      _.each(data?.order_payload, (value, key) => {
        this.orderPayload[key] = value
      })
    }

    if (data?.goods && !_.isEmpty(data?.goods)) {
      this.goods = _.map(data?.goods, good => ({
        name: _.get(good, 'name', ''),
        count: _.get(good, 'count', 1),
        price: _.get(good, 'price', 1),
        category: _.get(good, 'category', ''),
        manufacturer: _.get(good, 'manufacturer', '')
      }))
    }

    if (isSafe(data?.isHire)) this.isHire = data?.isHire
  })

  initRender = action(() => {
    this.isRender = true
  })
}

export { AppModel }
